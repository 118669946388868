import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import { User } from '../../models';

export type PdfType = 'Default' | 'Callback';
export type GetSessionSummaryInput = SessionInput &
	LanguageInput & {
		user?: User;
		pdfType: PdfType;
		callbackTime?: Date;
		returnAsUrl?: boolean;
		showUrgenceInPdf?: boolean;
	};
export type ApiOutputSessionSummary = {
	pdfUrl: string;
	urgence: string;
};
export type GetSessionSummaryResult = Blob | ApiOutputSessionSummary | undefined;

async function CallGetSessionSummary(apiKey: string, input: GetSessionSummaryInput): Promise<GetSessionSummaryResult> {
	try {
		const { data } = await minddApiAxios.post<Blob | ApiOutputSessionSummary>('GetSessionSummary?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			},
			responseType: input.returnAsUrl ? 'json' : 'blob'
		});

		if (input.returnAsUrl && 'pdfUrl' in data) {
			return data;
		}

		return data as Blob;
	} catch (error) {
		logAxiosError(error, 'CallGetSessionSummary');
	}

	return undefined;
}

export { CallGetSessionSummary };
